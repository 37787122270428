import React, { Component } from 'react';
import HomeIcon from '../../icons/home.svg';
import bmwImg from '../../../../assets/images/bmw-roundel.png';

class Header extends Component {
  render() {
    return (
      <header className="header">
        {this.topNav()}
        {this.hero()}
      </header>
    );
  }

  topNav() {
    return(
      <nav className="top-nav">
        <div className="container">
          <ul>
            <li>
              <a href="/">
                <span className="icon home">
                  <HomeIcon />
                </span>
                Home
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  hero() {
    return(
      <div className="hero">
        <div className="container">
          <h1>BMW PRODUCT UPDATES.</h1>
          {this.bmwModule()}
        </div>
      </div>
    );
  }

  bmwModule() {
    return(
      <figure className="bmw-module">
        <a href="/">
          <div className="module-top"><img src={bmwImg} alt="BMW Logo" /></div>
          <figcaption className="module-bottom">The Ultimate Driving Machine</figcaption>
        </a>
      </figure>
    );
  }
}

export default Header;
