import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UKPriceChangeIcon from './uk-price-change.svg';
import EIREPriceChangeIcon from './eire-price-change.svg';


class PriceChangeIcon extends Component {

  render() {
    let Icon;

    if (this.props.zone == 'uk') {
      Icon = UKPriceChangeIcon
    } else {
      Icon = EIREPriceChangeIcon
    }

    return (
      <Icon/>
    );
  }

}

PriceChangeIcon.propTypes = {
  zone: PropTypes.string.isRequired
};

export default PriceChangeIcon;
