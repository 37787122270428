import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import { setModalStatus } from '../../actions/modal';
import Details from '../../icons/details.svg';

class ModalButton extends Component {
  constructor () {
    super();

    bindAll(this, 'handleOpenModal');
  }

  handleOpenModal() {
    const { dispatch, content, type } = this.props;

    dispatch(setModalStatus({
      'modalOpen': true,
      type,
      content
    }));
  }

  render() {
    return (
      <button className="details-btn" onClick={this.handleOpenModal}><Details />DETAILS</button>
    );
  }
}

ModalButton.propTypes = {
  content: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(ModalButton);
