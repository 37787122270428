import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import Highlights from '../home/highlights';
import Search from '../search/index';
import SearchResults from '../search-results/index';
import classnames from 'classnames';
import { findDOMNode } from 'react-dom';

class Content extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onSearch', 'toggleSearch', 'hideSearch', 'onScroll');

    this.state = {
      searchActive: false,
      isSticky: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const searchForm = findDOMNode(this.refs.searchForm);
    const isSticky = searchForm.getBoundingClientRect().top < 0;

    if (isSticky !== this.state.isSticky) {
      this.setState({ ...this.state, isSticky });
    }
  }

  onSearch() {
    this.props.onSearch();
    this.hideSearch();
  }

  hideSearch() {
    const { searchActive } = this.state;

    this.setState({ searchActive: false });
  }

  toggleSearch() {
    const { searchActive } = this.state;

    this.setState({ searchActive: !searchActive });
  }

  render() {
    const searchClass = classnames(
      'search',
      {'is-visible': this.state.searchActive},
      {'is-sticky': this.state.isSticky}
    );

    return (
      <div className="container">
        <div className="main-content">{this.leftColumn()}</div>
        <div className={searchClass} ref="searchForm">{this.renderSearch()}</div>
      </div>
    );
  }

  leftColumn() {
    return this.props.showResults ? this.renderSearchResults() : this.renderHighlights();
  }

  renderHighlights() {
    return <Highlights />;
  }

  renderSearchResults() {
    return <SearchResults toggleSearch={this.toggleSearch} />;
  }

  renderSearch() {
    return <Search onSearch={this.onSearch} toggleSearch={this.toggleSearch} />;
  }
}

Content.propTypes = {
  bodyCode: PropTypes.number,
  bodyCodeWeeks: PropTypes.shape({
    weeks: PropTypes.arrayOf(PropTypes.shape({
      year: PropTypes.number.isRequired,
      week: PropTypes.number.isRequired
    })),
    yearList: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired
    }))
  })
};

const mapStateToProps = ({ bodyCode, bodyCodeWeeks }) => {
  return {
    bodyCode,
    bodyCodeWeeks
  }
};

export default connect(mapStateToProps)(Content);
