import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PDFIcon from '../../icons/pdf.svg';

class ProductLibrary extends Component {

  render() {
    const { productLibrary } = this.props;

    return (
      <div className="product-library">
        <a href={productLibrary.url} target="_blank"><PDFIcon className="pdf-icon" /> VIEW PRODUCT LIBRARY.</a>
      </div>
    );
  }
}

ProductLibrary.propTypes = {
  productLibrary: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

export default ProductLibrary;
