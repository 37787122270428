import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindAll } from 'lodash/util';
import PropTypes from 'prop-types';
import SearchIcon from '../../icons/search-icon.svg';
import dates from '../../utils/date';

class SearchCriteria extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClick');
  }

  onClick() {
    this.props.toggleSearch();
  }

  renderDate(className, label, jDate) {
    if (!jDate) return false;
    
    const year  = parseInt(jDate.substr(0, 4));
    const month = parseInt(jDate.substr(4, 2));
    const week  = parseInt(jDate.substr(6, 2));

    const monthName = dates.getNameOfMonthNum(month);

    return (
      <li className={className} >{label}: <em>Week {week} - {monthName}, {year}</em></li>
    );
  }

  renderKeywords(textSearch) {
    if (!textSearch) return false;

    return (
      <div className="search-keywords">
        <span>Keywords: </span>
        <span className="text-search"><em>{textSearch}</em></span>
      </div>
    );
  }

  render() {
    const {
      selectedSeriesLabel,
      selectedBodyLabel,
      selectedFromJDate,
      selectedToJDate,
      textSearch
    } = this.props.searchCriteria;

    return (
      <div className="search-criteria">

        <ul className="selected-model">
          <li className="series" >{selectedSeriesLabel}</li>
          <li className="body-code">{selectedBodyLabel}</li>
        </ul>

        <ul className="selected-date">
          {this.renderDate('from-date', 'From date', selectedFromJDate)}
          {this.renderDate('to-date', 'To date', selectedToJDate)}
        </ul>

        {this.renderKeywords(textSearch)}

        <hr className="search-divider" />

        <SearchIcon className="search-icon" onClick={this.onClick} />
      </div>
    );
  }
}

SearchCriteria.propTypes = {
  toggleSearch: PropTypes.func.isRequired,
  searchCriteria: PropTypes.shape({
    selectedSeriesLabel: PropTypes.string.isRequired,
    selectedBodyLabel: PropTypes.string.isRequired,
    selectedFromJDate: PropTypes.string,
    selectedToJDate: PropTypes.string,
    textSearch: PropTypes.string
  })
}

const mapStateToProps = ({ searchCriteria }) => {
  return { searchCriteria }
};

export default connect(mapStateToProps)(SearchCriteria);
