import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import SearchIcon from '../../icons/search-icon.svg';
import PlusIcon from '../../icons/plus.svg';
import CloseIcon from '../../icons/close.svg';

class SearchReveal extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClick');
  }

  onClick() {
    this.props.onToggle();
  }

  render() {
    return (
      <div className="search-reveal" onClick={this.onClick}>
        <SearchIcon className="search-icon" />
        <span className="search-label">SEARCH</span>
        <PlusIcon className="plus-icon" />
        <CloseIcon className="close-icon" />
      </div>
    );
  }
}

SearchReveal.propTypes = {
  onToggle: PropTypes.func.isRequired
};

export default SearchReveal;
