import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import ModelAdditionIcon from '../../icons/model-addition.svg';
import ModelDeletionIcon from '../../icons/model-deletion.svg';
import Currency from 'react-currency-formatter';

const ICONS = {
  addition: ModelAdditionIcon,
  discontinued: ModelDeletionIcon
}

const CURRENCY = "GBP";

class LineupModal extends Component {

  renderIcon(type) {
    const Icon = ICONS[type];

    return <Icon />;
  }

  render() {
    const { type, content } = this.props.modal;
    let vrt_content;

    if (content.zone == 'eire') {
      vrt_content = (<li>
        <span>VRT</span>
        <span>{content.vrt}</span>
      </li>);
    } else {
      vrt_content = '';
    }
    return (
      <div className={type}>
        <header>
          <h3 className="change-type">{this.renderIcon(content.status)} {content.name}</h3>
          <div className="option-code"><strong>MODEL CODE:</strong> {content.model_code}</div>
        </header>
        <div className="content">
          <ul className="pricelist">
            <li>
              <span>BASIC PRICE</span>
              <span>{content.basic_price}</span>
            </li>
            <li>
              <span>VAT</span>
              <span>{content.vat}</span>
            </li>
            {vrt_content}
            <li>
              <span>RETAIL PRICE FROM</span>
              <span>{content.retail_price_from}</span>
            </li>
            <li>
              <span>OTR FROM</span>
              <span>{content.otr}</span>
            </li>
              { content.zone == 'eire' ? '' : 
                <li>
                  <span>P11D</span>
                  <span>{content.p11d}</span>
                </li> }
              <li>
                <span>CO2</span>
                <span>{content.co2}</span>
              </li> 
            { content.zone == 'eire' ? '' : 
                <li>
                  <span>VED BAND</span>
                  <span>{content.ved_band}</span>
                </li> }
              { content.zone == 'eire' ? '' : 
              
                <li>
                  <span>BIK</span>
                  <span>{content.bik}</span>
                </li> }
          </ul>
        </div>
      </div>
    );
  }
}

LineupModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.shape({
      status: PropTypes.string,
      name: PropTypes.string,
      model_code: PropTypes.string,
      basic_price: PropTypes.string,
      vat: PropTypes.string,
      vrt: PropTypes.string,
      retail_price_from: PropTypes.string,
      otr: PropTypes.string,
      p11d: PropTypes.string,
      co2: PropTypes.string,
      ved_band: PropTypes.string,
      bik: PropTypes.string,
      zone: PropTypes.string
    })
  })
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(LineupModal);
