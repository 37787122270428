import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import { isEmpty } from 'lodash';
import PriceDownIcon from '../../icons/price-down.svg';
import PriceUpIcon from '../../icons/price-up.svg';
import PriceChangeIcon from '../../icons/price_change_icon';
import { toggleTermsVisibility } from '../../actions/modal';
import ReactMarkdown from 'react-markdown';

class PricesModal extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClickPriceProtectionToggle');
  }

  onClickPriceProtectionToggle() {
    const { dispatch } = this.props;

    dispatch(toggleTermsVisibility());
  }

  renderPriceIcon(amount) {
    const Icon = amount > 0 ? PriceUpIcon : PriceDownIcon;

    return <Icon />;
  }

  render() {
    const { type, content, termsVisible } = this.props.modal;
    let uk_content, price_protection_content, price_protection_terms_content, price_protection_terms_section;

    if (content.zone == 'eire') {
      uk_content = '';
    } else {
      uk_content = (<li>
        <span>NEW P11D</span>
        <span>{content.new_p11d}</span>
      </li>);
    }

    if (content.price_protection_value.length > 0) {
      price_protection_content = (<li>
        <span>PRICE PROTECTION VALUE</span>
        <span>{content.price_protection_value}</span>
      </li>);
    } else {
      price_protection_content = '';
    }

    if (isEmpty(content.price_protection_terms)) {
      price_protection_terms_section = '';
    } else {
      if (termsVisible) {
        price_protection_terms_content = (<div className="price-protection-content"><ReactMarkdown source={content.price_protection_terms} /></div>);
      } else {
        price_protection_terms_content = '';
      }

      price_protection_terms_section = (<div className="price-protection-section closed">
        <a className="price-protection-toggle" href="#" onClick={this.onClickPriceProtectionToggle}>Price Protection Terms</a>
        {price_protection_terms_content}
      </div>);
    }

    return (
      <div className={type}>
        <header>
          <h3 className="change-type"><PriceChangeIcon zone={content.zone} /> {content.name}</h3>
          <div className="option-code"><strong>MODEL CODE:</strong> {content.model_code}</div>
        </header>
        <div className="content">
          <ul className="pricelist">
            <li>
              <span>OLD RRP</span>
              <span>{content.old_rrp}</span>
            </li>
            <li>
              <span>NEW RRP</span>
              <span>{content.new_rrp}</span>
            </li>
            <li>
              <span>CHANGE RRP</span>
              <span>{content.change_rrp}</span>
            </li>
            <li>
              <span>NEW OTR FROM</span>
              <span>{content.new_otr}</span>
            </li>
            {uk_content}
            {price_protection_content}
          </ul>
          {price_protection_terms_section}
        </div>
      </div>
    );
  }
}

PricesModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.shape({
      status: PropTypes.string,
      name: PropTypes.string,
      model_code: PropTypes.string,
      old_rrp: PropTypes.string,
      new_rrp: PropTypes.string,
      change_rrp: PropTypes.string,
      new_otr: PropTypes.string,
      new_p11d: PropTypes.string,
    })
  })
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(PricesModal);
