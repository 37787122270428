import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PriceDownIcon from '../../icons/price-down.svg';
import PriceUpIcon from '../../icons/price-up.svg';
import PriceChangeIcon from '../../icons/price_change_icon';
import ModalButton from './modal-button.jsx';

class Prices extends Component {
  renderModalButton(item) {
    return (
      <ModalButton type="prices" content={item} />
    );
  }

  render() {
    return (
      <div className="weekly-section prices">
        <h3>MODEL PRICE CHANGES.</h3>
        {this.props.items.map((item) =>
          <div className="change-item" key={item.id}>
            <h3 className="type model-name"><PriceChangeIcon zone={item.zone} /> {item.name}</h3>
            <p className="title"><strong>PRICE CHANGE:</strong> {item.change_rrp}</p>
            <p className="code"><strong>MODEL CODE:</strong> {item.model_code}</p>
            {this.renderModalButton(item)}
          </div>
        )}
      </div>
    );
  }

  renderPriceIcon(amount) {
    const ChangeIcon = amount > 0 ? PriceUpIcon : PriceDownIcon;

    return <ChangeIcon />;
  }
}

Prices.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    model_code: PropTypes.string.isRequired
  })).isRequired
};

export default Prices;
